import React, { ReactElement } from "react";
import { NavHashLink as Link } from "react-router-hash-link";

const Card = (props: {
  className?: string;
  imageSrc: string;
  height?: string;
  title: string;
  caption: string;
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  link: string;
}): ReactElement => {
  const { className = "all-cards-same-size", imageSrc, height, title, caption, link, objectFit } = props;
  return (
    <div className={"p-1 " + className}>
      <div className="card">
        <img
          src={`/images/` + imageSrc + `.jpg`}
          className="card-img-top"
          alt={imageSrc}
          style={{ height: height, objectFit: objectFit }}
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{caption}</p>
          <Link
            to={link}
            className="btn
                btn-primary-color"
            onClick={(): void => {
              window.scrollTo(0, 0);
            }}
          >
            Lees verder
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
