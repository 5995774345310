import React from "react";
import Contact from "../Contact/Contact";
import TopNavigationbar from "../TopNavigationbar/TopNavigationbar";
import Footer from "../Footer/Footer";
import CardWrapper from "../Card/CardWrapper";
import Card from "../Card/Card";

const Home = () => {
  return (
    <>
      <div className="App">
        <header id="top">
          <TopNavigationbar />
          <div className="container-fluid">
            <div className="row">
              <div className="header-img">
                <div className="home-block">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <section className="my-4 flex">
                          <article>
                            <p>
                              Nadeelclaim.nl is opgericht door jurist mr. Nick Voorbach. Nadeelclaim ontleent zijn naam
                              aan het ‘nadeelcompensatierecht’. Dat is het stukje recht dat zich bezig houdt met
                              vergoedingen die de overheid moet betalen, vanwege infrastructurele werkzaamheden (denk
                              aan bouw- of wegwerkzaamheden). Ondernemers, winkeliers of uitbaters kunnen in aanmerking
                              komen voor een nadeelcompensatievergoeding. Mailt of belt u vrijblijvend met mr. Nick
                              Voorbach. Hij is specialist op het gebied van nadeelcompensatie en heeft meer dan
                              tweeduizend rechtszaken gevoerd.
                            </p>
                          </article>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="container my-5" id="blog">
          <div className="flex">
            <h3 className="pb-4">Blog</h3>
            <section>
              <article>
                <p>
                  Wij delen onze kennis en ervaring. U vindt hier een selectie van interessante uitspraken, juridische
                  tips en praktische informatie.
                </p>
              </article>
            </section>
          </div>
          <CardWrapper>
            <Card
              imageSrc="blog-small"
              title="Het nadeelcompensatierecht uitgelegd in vogelvlucht"
              caption="Het stelsel van nadeelcompensatie is gebaseerd op het algemene beginsel van behoorlijk bestuur,
                        zijnde het beginsel van de gelijke verdeling van de openbare lasten (in het Frans: égalité
                        devant les charges publiques). Dit beginsel gaat ervan uit dat bij omvangrijke schade, het in
                        zijn algemeenheid redelijk is dat de samenleving als geheel een gedeelte voor haar rekening
                        neemt."
              link="Nadeelcompensatie"
            ></Card>
          </CardWrapper>
        </main>

        <main id="contact">
          <Contact />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;
