import React, { ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./shared/components/Home/Home";
import Nadeelcompensatie from "./shared/components/Blog/Nadeelcompensatie";

import smoothscroll from "smoothscroll-polyfill";
import "./styles/style.scss";

smoothscroll.polyfill();

const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Nadeelcompensatie" component={Nadeelcompensatie} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
