import React from "react";
import { Link } from "react-router-dom";

function currYear() {
  let year = new Date().getFullYear();
  return year;
}

const Footer = () => {
  return (
    <>
      <div className="upper-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <section>
                <h3>Recente berichten</h3>
                <ul>
                  <li>
                    <Link
                      to="/Nadeelcompensatie"
                      className="black-link py-1"
                      onClick={(): void => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Het nadeelcompensatierecht uitgelegd in vogelvlucht
                    </Link>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-md-6 col-12">
              <section>
                <h3>Contactgegevens</h3>
                <ul>
                  <li>Postbus 7222, 2701 AE Zoetermeer</li>
                  <li>
                    <a href="tel:0792033000" className="black-link">
                      079 203 3000
                    </a>{" "}
                  </li>
                  <li>
                    <a href="mailto:info@nadeelclaim.nl" className="black-link">
                      info@nadeelclaim.nl
                    </a>
                  </li>
                  <li>Kantooruren: 9:00 uur – 20:00 uur</li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="copyright-text">
                <p>
                  Nadeelclaim © <span>{currYear()}</span> All rights reserved -{" "}
                  <a href="/docs/privacyverklaring.pdf" target="_blank" className="black-link">
                    Privacyverklaring
                  </a>{" "}
                  - Made by{" "}
                  <a href="https://rdewilde.nl/" target="_blank" rel="noopener noreferrer" className="link">
                    Rdewilde.nl
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
