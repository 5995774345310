import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Wrapper from "../Wrapper/Wrapper";
import MailBase from "../MailBase/MailBase";
import { renderToString } from "react-dom/server";
import classNames from "classnames";

const SignupSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "De ingevulde naam is te kort, er zijn minimaal twee tekens nodig!")
    .max(100, "De ingevulde naam is te lang, vul een kortere naam in.")
    .required("Vul een voor- en achternaam in."),
  Email: Yup.string()
    .email("Voer een geldig e-mailadres in!")
    .required("Vul een e-mailadres in."),
  Message: Yup.string()
    .min(2, "Het ingevulde bericht is te kort, voer een langer bericht in")
    .required("Vul een bericht in.")
});

const getMailInput = (values: any) => {
  return {
    personalizations: [
      {
        to: [
          {
            email: "n.voorbach@outlook.com"
          }
        ],
        subject: "Contact via nadeelclaim contactformulier"
      }
    ],
    from: {
      email: "no-reply@nadeelclaim.nl"
    },
    content: [
      {
        type: "text/html",
        value: renderToString(<MailBase name={values.Name} emailadres={values.Email} message={values.Message} />)
      }
    ]
  };
};
const Contact = () => (
  <>
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="flex pb-4">
              <h3>Neem Contact op</h3>
            </div>
          </div>
        </div>
        <Formik
          isInitialValid
          initialValues={{
            Name: "",
            Email: "",
            Message: ""
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions): void => {
            fetch("https://cors-anywhere.herokuapp.com/https://api.sendgrid.com/v3/mail/send", {
              method: "post",
              headers: {
                Authorization: "Bearer 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",
                "Content-Type": "application/json"
              },
              body: JSON.stringify(getMailInput(values))
            });
            actions.setStatus({
              msg: "De e-mail is verstuurd, ik neem zo spoedig mogelijk contact met je op!"
            });
            actions.resetForm();
            setTimeout(() => {
              alert("De e-mail is verstuurd, ik neem zo spoedig mogelijk contact met je op!");
            }, 1000);
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="form-group form-row">
                <div className="col-12">
                  <div className={classNames("input-group", errors.Name && touched.Name && "has-error")}>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Voor- en achternaam"
                      name="Name"
                      value={values.Name}
                      required
                    />
                  </div>
                  {errors.Name && touched.Name ? <div className="invalid-feedback">{errors.Name}</div> : null}
                </div>
              </div>
              <div className="form-group form-row">
                <div className="col-12">
                  <div className={classNames("input-group", errors.Email && touched.Email && "has-error")}>
                    <Field
                      type="email"
                      className="form-control"
                      placeholder="E-mailadres"
                      name="Email"
                      value={values.Email}
                      required
                    />
                  </div>
                  {errors.Email && touched.Email ? <div className="invalid-feedback">{errors.Email}</div> : null}
                </div>
              </div>
              <div className="form-group form-row">
                <div className="col-12">
                  <div className={classNames("input-group", errors.Message && touched.Message && "has-error")}>
                    <Field
                      type="text"
                      component="textarea"
                      className="form-control"
                      placeholder="Typ hier je bericht"
                      name="Message"
                      value={values.Message}
                      required
                    />
                  </div>
                  {errors.Message && touched.Message ? <div className="invalid-feedback">{errors.Message}</div> : null}
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary-color"
                  disabled={!values.Name && !values.Email && !values.Message}
                >
                  Verzenden
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Wrapper>
  </>
);

export default Contact;
