import React, { ReactElement } from "react";
import { withRouter } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Navbar, Nav } from "react-bootstrap";

const TopNavigationbar = (): ReactElement => {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">
        <div className="brand-logo" />
        <div className="brand-word">Nadeelclaim</div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavLink className="nav-item nav-link" activeClassName="active" smooth to="/">
            Home <span className="sr-only">(current)</span>
          </NavLink>
          <NavLink className="nav-item nav-link" activeClassName="active" smooth to="/#blog">
            Blog
          </NavLink>
          <NavLink className="nav-item nav-link" activeClassName="active" smooth to="/#contact">
            Contact
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withRouter(TopNavigationbar);
