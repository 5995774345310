import React, { ReactElement } from "react";

let tableSettings = {
  width: "600px",
  margin: "0 auto"
};

let headerStyle = {
  font: "Montserrat",
  fontSize: "20px",
  color: "#000000",
  marginBottom: "10px"
};

let textStyle = {
  font: "PT Sans",
  fontSize: "15px",
  color: "#000000"
};

const MailBase = (props: { name: string; emailadres: string; message: string }): ReactElement => {
  return (
    <>
      <table style={tableSettings}>
        <tbody>
          <tr>
            <td
              style={{
                padding: "30px 69px 30px 69px",
                border: "1px solid #000000",
                backgroundColor: "#FFFFFF"
              }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <div style={headerStyle}>Contact via contactformulier</div>
                      <p style={textStyle}>
                        Naam:{""}
                        {props.name}
                      </p>
                      <p style={textStyle}>
                        E-mail:{""}
                        {props.emailadres}
                      </p>
                      <p style={textStyle}>
                        Bericht:{""}
                        {props.message}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default MailBase;
