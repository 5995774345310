import React, { ReactNode, ReactElement } from "react";
import classnames from "classnames";

type WrapperProps = {
  children: ReactNode;
};

const Wrapper = ({ children }: WrapperProps): ReactElement => {
  return <div className={classnames("d-flex flex-wrap px-3")}>{children}</div>;
};

export default Wrapper;
