import React, { ReactNode, ReactElement } from "react";
import classnames from "classnames";
import Card from "./Card";

type CardWrapperProps = {
  children: ReactNode;
  maxRowCount?: number;
  flexType?: "flex-row" | "flex-column";
  className?: string;
};

const CardWrapper = ({
  children,
  maxRowCount = 6,
  flexType = "flex-row",
  className
}: CardWrapperProps): ReactElement => {
  // We vullen de row op met hidden card elementen tot het maxRowCount; om op deze manier de breedte van het totaal aan kaarten in een row te beperken.
  let count = 1;

  React.Children.map(children, child => {
    if ((child as ReactElement<any, any>).type === Card) count++;
  });

  let hiddens: ReactNode[] = [];
  if (count < maxRowCount && count > 0) {
    while (count < maxRowCount) {
      hiddens.push(
        <div className="hidden-flexbox" key={`hidden-${count}`}>
          <div className="card" />
        </div>
      );
      count++;
    }
  }

  return (
    <div className={classnames("d-flex flex-wrap px-lg-3 px-md-3 px-sm-3", className, flexType)}>
      {children}
      {hiddens}
    </div>
  );
};

export default CardWrapper;
