import React from "react";

import TopNavigationbar from "../TopNavigationbar/TopNavigationbar";
import Footer from "../Footer/Footer";

const Nadeelcompensatie = () => {
  return (
    <>
      <header id="top">
        <TopNavigationbar />
        <div className="container-fluid">
          <div className="row">
            <div className="header-blog" />
          </div>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-12 pb-4">
              <h3>Het nadeelcompensatierecht uitgelegd in vogelvlucht</h3>
              <section>
                <article>
                  <p>
                    Het stelsel van nadeelcompensatie is gebaseerd op het algemene beginsel van behoorlijk bestuur,
                    zijnde het beginsel van de gelijke verdeling van de openbare lasten (in het Frans: égalité devant
                    les charges publiques). Dit beginsel gaat ervan uit dat bij omvangrijke schade, het in zijn
                    algemeenheid redelijk is dat de samenleving als geheel een gedeelte voor haar rekening neemt.
                  </p>
                  <p>
                    Er is nog geen expliciete wettelijke grondslag voor het nadeelcompensatierecht. De wetgever is al
                    sinds 2013 bezig met de invoering van een wet die het nadeelcompensatierecht zal normeren (titel 4.5
                    van de Awb). Het is onduidelijk wanneer de wet in gaat. Hoewel er nog geen expliciete wet van kracht
                    is, wordt aangenomen dat art. 3:4 van de Algemene wet bestuursrecht (de ‘Awb’) een meer algemene
                    norm is voor nadeelcompensatie.
                  </p>
                  <p>
                    Het nadeelcompensatierecht wordt overwegend genormeerd door ‘rechtersrecht’ (jurisprudentie). Een
                    ondernemer dient een aanvraag in om nadeelcompensatie en uiteindelijk beslist de rechter of de
                    overheid een nadeelcompensatievergoeding (een schadevergoeding) moet betalen. Uit deze uitspraken
                    kunnen algemene criteria worden gefilterd, die we weer kunnen toepassen op nieuwe zaken. Zo is het
                    Nederlands nadeelcompensatierecht ontstaan.
                  </p>
                  <p>
                    <b>De criteria</b>
                    <br />
                    Elke zaak is uniek en vergt maatwerk van een gespecialiseerd jurist. Er zijn inmiddels wel enige
                    criteria te destilleren, namelijk:
                    <ul>
                      <li>er moet schade zijn (de omzet is bijvoorbeeld drastisch gedaald);</li>
                      <li>de schade overstijgt het normale ondernemersrisico (onevenredig nadeel);</li>
                      <li>er is een duidelijk verband tussen de weg- of bouwwerkzaamheden en de omzetdaling;</li>
                      <li>
                        de schade mag niet voorzienbaar zijn (als u als uitbater weet dat de weg op enig moment wordt
                        opengebroken, kunt u in principe geen nadeelcompensatie claimen)
                      </li>
                    </ul>
                  </p>
                  <p>
                    Zie voor een goede Handleiding over het systeem van{" "}
                    <a
                      href="https://www.rijksoverheid.nl/documenten/brochures/2018/07/20/handleiding-nadeelcompensatie-bij-infrastructurele-maatregelen"
                      target="_blank"
                      className="link"
                      rel="noopener noreferrer"
                    >
                      nadeelcompensatie
                    </a>
                    .
                  </p>
                  <p>
                    Er gelden ook nog (veel) procedurele vereisten. Deze vereisten lenen zich niet voor dit blog en zijn
                    veel te technisch.
                    <br />
                    <br />
                    <b>Bespreek vrijblijvend uw zaak</b>
                    <br />
                    Denkt u in aanmerking te komen voor nadeelcompensatie of hebt u gewoon wat vragen? Mail of bel mr.
                    Nick Voorbach geheel vrijblijvend.
                    <br />
                    <br />
                    <b>Taaldisclaimer</b> <br />
                    Omwille van de leesbaarheid voor de doelgroep – ondernemers zonder juridische bagage – is soms
                    gekozen voor praktische termen en zijn nuances weggelaten.{" "}
                  </p>
                </article>
              </section>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Nadeelcompensatie;
